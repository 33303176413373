import React from'react'
import Layout from '../components/layout'
import Img from 'gatsby-image'
import { graphql } from 'gatsby'
import '../styles/packages.css'
import { Row, Col, Container} from "react-bootstrap"
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

export const data = graphql`

query($slug: String!) {
  contentfulHealthPackages (slug: {eq:$slug}){
    place
    nights
    days
    price
    transportationModes
    slug
    featuredImage{
      fluid{
        src
      }
    }
    content{
      json
    }
  }
}
`

const packages = probs => {
    return (
        <Layout>
            <div className="padding">
            <div className="overlay">
            <Img className="packages_background "
                fluid={probs.data.contentfulHealthPackages.featuredImage.fluid}
            > </Img>
            </div> 
                <Row className="padding-lr"> 
                  
                    <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                    <div className="packages_vr_line"></div>

                         <h2 className="packages_place">{probs.data.contentfulHealthPackages.place}</h2>
                          <h5 className="stay_dtl">{probs.data.contentfulHealthPackages.nights} Nights & {probs.data.contentfulHealthPackages.days} Days</h5>
                          <p className="packages_banner">{probs.data.contentfulHealthPackages.transportationModes}</p>
                    </Col>

                      <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                         <p className="packages_price">starting from <b>Rs.{probs.data.contentfulHealthPackages.price}</b></p>

                      </Col>
                </Row>

          
            </div>

                <Container>
                <div className="packages_txt">
                  {documentToReactComponents(probs.data.contentfulHealthPackages.content.json)}
                  </div>
                </Container>
        </Layout>
    )
}

export default packages